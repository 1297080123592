@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Simvoni";
  font-weight: normal;
  src: url("./Simvoni.otf");
}

@font-face {
  font-family: "Simvoni";
  font-weight: bold;
  src: url("./SimvoniBold.otf");
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Simvoni";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e0c2FF;
  background-image: url("./LinieGelb_desk.svg");
  background-position: center center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/* @media only screen and (max-width: 600px) {
  body {
    background-image: url("./LinieGelb.svg") !important;
  }
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.boldText{
  font-family: "Simvoni";
  font-weight: bold;
  font-size: 30pt;
  hyphens: auto;
}

.solidBg{
  background-color: #e0c2FF;
}

.negativeZ{
  z-index: -10;
}